import { AuctionProgressStatus } from '@bonhams1793/bonhams-typescript'

import { getTranslate } from '@nx/translations'

import translate from './getAuctionStatusLabels.translate.json'

const { text } = getTranslate(translate)

export function getAuctionStatusLabels(location: 'card' | 'status') {
  const AUCTION_STATUS_LABEL: Record<AuctionProgressStatus, string> = {
    [AuctionProgressStatus.consignment]:
      location === 'status'
        ? text('auctionStatusLabel.consignment-status')
        : text('auctionStatusLabel.consignment-card'),
    [AuctionProgressStatus.openNow]: text('auctionStatusLabel.openNow'),
    [AuctionProgressStatus.consignmentReady]:
      location === 'status'
        ? text('auctionStatusLabel.consignmentReady-status')
        : text('auctionStatusLabel.consignmentReady-card'),
    [AuctionProgressStatus.comingSoon]: text('auctionStatusLabel.comingSoon'),
    [AuctionProgressStatus.previewReady]: text(
      'auctionStatusLabel.previewReady'
    ),
    [AuctionProgressStatus.ready]: text('auctionStatusLabel.ready'),
    [AuctionProgressStatus.live]: text('auctionStatusLabel.live'),
    [AuctionProgressStatus.ending]: text('auctionStatusLabel.ending'),
    [AuctionProgressStatus.ended]: text('auctionStatusLabel.ended'),
  }

  return AUCTION_STATUS_LABEL
}
