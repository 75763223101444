import { Fragment } from 'react'

import { Brand } from '@bonhams1793/bonhams-typescript'

import { AlgoliaAuction } from '@nx/algolia'
import { FireAuctionCard } from '@nx/fire/auction-card'
import { FireBackToTop } from '@nx/fire/back-to-top'
import { FireEventCardProps } from '@nx/fire/event-card'
import { Heading } from '@nx/fire/typography'
import { stripTags } from '@nx/helpers'
import { TypesenseAuction } from '@nx/typesense'
import { InteractionAction } from '@nx/utils/google-tag-manager'

import {
  MonthAndYearContainer,
  MonthAndYearSeparatorContainer,
  StyledAddToCalendarIcon,
  StyledGrid,
} from './FireAuctionGrid.styles'
import { auctionGridItemMapper } from './auctionGridMappers'

interface FireAuctionGridProps {
  auctions: (AlgoliaAuction | TypesenseAuction)[]
  pageIdPrefix: string
  auctionTitleTag: keyof JSX.IntrinsicElements
  separateByDate?: boolean
  cardAlignment?: FireEventCardProps['selfAlignment']
  auctionClickCallback?: (props: {
    auctionId: string | number
    category: string
    action: InteractionAction
  }) => void
  auctionsPerPage: number
  urlBuilder: (
    path: string,
    ...params: (Record<string, string | number | boolean> | string)[]
  ) => string
  getDomain?: (props: { brand: Brand; domain?: string }) => string | undefined
  host?: string
}

export const FireAuctionGrid = ({
  auctionTitleTag,
  auctions,
  pageIdPrefix,
  separateByDate = false,
  cardAlignment,
  auctionClickCallback,
  auctionsPerPage,
  urlBuilder,
  getDomain,
  host,
}: FireAuctionGridProps) => {
  return auctions.length > 0 ? (
    <StyledGrid>
      {auctions.map((rawAuction, index) => {
        const auction = auctionGridItemMapper(rawAuction, urlBuilder)

        if (!auction) return null

        const showHeading =
          !auctions[index - 1] ||
          auction.monthAndYear !== auctions[index - 1].monthAndYear

        const pageNumber =
          index % auctionsPerPage === 0
            ? `page-${pageIdPrefix}${index / auctionsPerPage + 1}`
            : undefined

        return (
          <Fragment key={index}>
            {separateByDate && showHeading && (
              <MonthAndYearSeparatorContainer $hideBackToTop={index < 5}>
                <FireBackToTop id="auctions-gallery" jumpToName="auction">
                  <MonthAndYearContainer>
                    <StyledAddToCalendarIcon />
                    <Heading $level={3} as="h2">
                      {auction.monthAndYear}
                    </Heading>
                  </MonthAndYearContainer>
                </FireBackToTop>
              </MonthAndYearSeparatorContainer>
            )}
            <FireAuctionCard
              key={auction.id}
              {...auction}
              id={pageNumber}
              name={stripTags(auction.name)}
              auctionId={auction.id}
              lotCount={auction.numLots}
              auctionTitleTag={auctionTitleTag}
              selfAlignment={cardAlignment}
              brand={auction.brand}
              slug={auction.slug}
              url={auction.url}
              auctionClickCallback={(props) =>
                auctionClickCallback?.({
                  ...props,
                  category: 'Auction Grid',
                })
              }
              domain={getDomain?.({ brand: auction.brand, domain: host })}
            />
          </Fragment>
        )
      })}
    </StyledGrid>
  ) : null
}
