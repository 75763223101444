import { Brand } from '@bonhams1793/bonhams-typescript'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export const getAuctionDomain = ({
  brand,
  domain,
}: {
  brand: Brand
  domain?: string
}) => {
  let auctionDomain = undefined

  if (brand === Brand.cars) {
    auctionDomain = publicRuntimeConfig.CARS_BASE_URL
  }

  if (domain && domain !== new URL(publicRuntimeConfig.WEB_BASE_URL).host) {
    if (brand === Brand.cornette || brand === Brand.skinner) {
      auctionDomain = publicRuntimeConfig.WEB_BASE_URL
    }
  }

  return auctionDomain
}
