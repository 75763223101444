import {
  AuctionBiddingEngine,
  Brand,
  EAuctionBiddingEngine,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
} from '@bonhams1793/bonhams-typescript'

import { AlgoliaAuction } from '@nx/algolia'
import { FireEventCardImage } from '@nx/fire/event-card'
import { TypesenseAuction } from '@nx/typesense'
import { logger } from '@nx/utils/logger'

interface AuctionGridItem {
  id: number | string
  name: string
  type: EAuctionType
  status: EAuctionStatus
  localTimezone: string
  timezoneFormat: string
  startDate: string | number
  endDate: string | number
  location: string
  numLots: number
  image?: FireEventCardImage
  biddingStatus: EAuctionBiddingStatus
  biddingEngine: EAuctionBiddingEngine
  monthAndYear: string
  consignmentEndDate: string | number | null
  brand: Brand
  slug?: string
  url?: string
}

export const auctionGridItemMapper = (
  auction: AlgoliaAuction | TypesenseAuction,
  urlBuilder: (
    path: string,
    ...params: (Record<string, string | number | boolean> | string)[]
  ) => string
): AuctionGridItem | null => {
  try {
    if (
      typeof auction?.dates?.start?.timestamp !== 'number' ||
      typeof auction?.dates?.end?.timestamp !== 'number' ||
      typeof auction?.dates?.start?.timezone?.iana !== 'string' ||
      typeof auction?.dates?.start?.timezone?.abbreviation !== 'string' ||
      typeof auction?.lots?.total !== 'number'
    ) {
      throw new Error()
    }

    return {
      id: auction.id,
      name: auction.auctionTitle,
      type: auction.auctionType,
      status: auction.auctionStatus,
      biddingStatus: auction.biddingStatus,
      localTimezone: auction.dates.start.timezone.iana,
      timezoneFormat: auction.dates.start.timezone.abbreviation,
      startDate: auction.dates.start.timestamp * 1000,
      endDate: auction.dates.end.timestamp * 1000,
      location:
        auction.biddingEngine === AuctionBiddingEngine.BRK ||
        auction.biddingEngine === AuctionBiddingEngine.BUK
          ? auction.country?.name || ''
          : auction.venue?.name || '',
      biddingEngine: auction.biddingEngine,
      numLots: auction.lots.total,
      image: auction.image
        ? {
            url: urlBuilder(auction.image.url, auction.image.URLParams),
            width: auction.image.width ?? undefined,
            height: auction.image.height ?? undefined,
            caption: auction.image.caption,
          }
        : undefined,
      monthAndYear: auction.monthAndYear,
      consignmentEndDate: auction.dates.consignment?.timestamp
        ? auction.dates.consignment?.timestamp * 1000
        : null,
      brand: auction.brand,
      slug: auction.slug,
      url: auction.url,
    }
  } catch {
    logger.error(auction, `Error mapping Algolia auction to grid item`)
    return null
  }
}
