import {
  AuctionBiddingEngine,
  AuctionProgressStatus,
  AuctionType,
  EAuctionBiddingEngine,
  EAuctionType,
} from '@bonhams1793/bonhams-typescript'

import { getTranslate } from '@nx/translations'

import translate from './use-get-auction-cta-text.translate.json'

const { text, textHTML } = getTranslate(translate)

export const useGetAuctionCTAText = ({
  auctionStatus,
  type,
  biddingEngine,
  lotCount,
}: {
  auctionStatus: AuctionProgressStatus
  type: EAuctionType
  biddingEngine: EAuctionBiddingEngine
  lotCount?: number
}) => {
  if (biddingEngine === AuctionBiddingEngine.BRK) {
    return lotCount && lotCount > 0
      ? textHTML(lotCount === 1 ? 'viewNumberLot' : 'viewNumberLots', {
          count: (
            <span
              key={Math.random().toString(36).slice(2)}
              className="sl_plural"
            >
              {lotCount}
            </span>
          ),
        })
      : text('viewLots')
  }

  switch (auctionStatus) {
    case AuctionProgressStatus.comingSoon:
      return text(
        type === AuctionType.exhibition ? 'exhibitionDetails' : 'auctionDetails'
      )
    case AuctionProgressStatus.openNow:
      return text('viewExhibition')
    case AuctionProgressStatus.consignment:
    case AuctionProgressStatus.previewReady:
    case AuctionProgressStatus.consignmentReady:
      if (lotCount === 0 || typeof lotCount === 'undefined') {
        return text('auctionDetails')
      }

      return textHTML(
        lotCount === 1 ? 'previewNumberLot' : 'previewNumberLots',
        {
          count: (
            <span
              key={Math.random().toString(36).slice(2)}
              className="sl_plural"
            >
              {lotCount}
            </span>
          ),
        }
      )
    case AuctionProgressStatus.ready:
    case AuctionProgressStatus.ending:
      return lotCount && lotCount > 0
        ? textHTML(lotCount === 1 ? 'viewNumberLot' : 'viewNumberLots', {
            count: (
              <span
                key={Math.random().toString(36).slice(2)}
                className="sl_plural"
              >
                {lotCount}
              </span>
            ),
          })
        : text('viewLots')
    case AuctionProgressStatus.live:
      return text('watchLive')
    case AuctionProgressStatus.ended:
      return text(
        type === AuctionType.exhibition ? 'viewExhibition' : 'viewResults'
      )
  }
}
