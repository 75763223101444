import {
  AuctionBiddingEngine,
  AuctionProgressStatus,
  AuctionType,
  Brand,
  EAuctionBiddingEngine,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
  auctionDate,
  getAuctionProgressStatus,
} from '@bonhams1793/bonhams-typescript'

import {
  FireEventCard,
  FireEventCardImage,
  FireEventCardProps,
} from '@nx/fire/event-card'
import { Heading } from '@nx/fire/typography'
import {
  buildAuctionPath,
  getAuctionStatusLabels,
  stripTags,
} from '@nx/helpers'
import { useGetAuctionCTAText } from '@nx/hooks/use-get-auction-cta-text'
import { getTranslate } from '@nx/translations'
import { InteractionAction } from '@nx/utils/google-tag-manager'

import translate from './FireAuctionCard.translate.json'

const { text } = getTranslate(translate)

const AUCTION_STATUS_LABELS = getAuctionStatusLabels('card')

export function FireAuctionCard({
  id,
  auctionId,
  name,
  type,
  startDate,
  endDate,
  lotCount,
  localTimezone,
  timezoneFormat,
  biddingEngine,
  location,
  image,
  status,
  biddingStatus,
  auctionTitleTag,
  selfAlignment,
  consignmentEndDate,
  brand,
  slug,
  url,
  domain,
  auctionClickCallback,
}: FireAuctionCardProps) {
  const auctionStatus = getAuctionProgressStatus({
    startDate,
    endDate,
    localTimezone,
    status,
    type,
    biddingStatus,
    lotCount,
    consignmentEndDate,
  })

  const ctaText = useGetAuctionCTAText({
    auctionStatus,
    biddingEngine,
    type,
    lotCount,
  })

  const isExhibition = type === AuctionType.exhibition

  const showLightStatus =
    biddingEngine !== AuctionBiddingEngine.BRK &&
    [
      AuctionProgressStatus.consignment,
      AuctionProgressStatus.comingSoon,
      AuctionProgressStatus.previewReady,
      AuctionProgressStatus.consignmentReady,
    ].includes(auctionStatus)

  const hideImage =
    !isExhibition &&
    [
      AuctionProgressStatus.consignment,
      AuctionProgressStatus.comingSoon,
    ].includes(auctionStatus)

  const isEventEnded = auctionStatus === AuctionProgressStatus.ended

  const auctionStatusLabel =
    biddingEngine === AuctionBiddingEngine.BRK
      ? AUCTION_STATUS_LABELS[AuctionProgressStatus.ready]
      : isEventEnded
        ? undefined
        : AUCTION_STATUS_LABELS[auctionStatus]

  let finalLocation = location

  if (type === AuctionType.live) {
    finalLocation = location
      ? `${location} | ${text('liveAuction')}`
      : text('liveAuction')
  }

  return (
    <FireEventCard
      id={id}
      data-testid={id}
      title={
        <Heading
          as={auctionTitleTag}
          $level={6}
          dangerouslySetInnerHTML={{
            __html: stripTags(name),
          }}
        />
      }
      date={auctionDate(
        localTimezone,
        timezoneFormat,
        startDate,
        endDate,
        type,
        auctionStatus
      )}
      location={finalLocation}
      image={image}
      isLightStatus={showLightStatus}
      showImage={!hideImage}
      ctaText={ctaText}
      ctaLink={buildAuctionPath({
        auctionId,
        auctionType: type,
        biddingEngine: biddingEngine,
        domain,
        slug,
        url,
      })}
      onClick={() => {
        auctionClickCallback?.({
          auctionId,
          category: 'Auction Card',
          action: 'auction-card.click',
        })
      }}
      statusLabel={!isExhibition ? auctionStatusLabel : undefined}
      showArrowIcon={isEventEnded}
      isLightCta={isEventEnded}
      selfAlignment={selfAlignment}
    />
  )
}

export interface FireAuctionCardProps {
  id?: string | undefined
  auctionId: string | number
  status: EAuctionStatus
  type: EAuctionType
  biddingStatus: EAuctionBiddingStatus
  biddingEngine: EAuctionBiddingEngine
  name: string
  lotCount?: number
  localTimezone: string
  timezoneFormat: string
  startDate: string | number
  endDate: string | number
  location: string
  image?: FireEventCardImage
  auctionTitleTag: keyof JSX.IntrinsicElements
  selfAlignment?: FireEventCardProps['selfAlignment']
  consignmentEndDate: string | number | null
  brand: Brand
  slug?: string
  url?: string
  auctionClickCallback?: (params: {
    auctionId: string | number
    category: string
    action: InteractionAction
  }) => void
  domain?: string
}
